<template>
  <div v-if="text !== ''">
    <blockquote v-if="useQuote" v-html="parsedText" class="d-inline-flex"></blockquote>
    <div v-else v-html="parsedText"></div>
  </div>
</template>

<script>
export default {
  name: 'TextView',
  props: {
    text: {
      type: String,
      required: true,
    },
    useQuote: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedText() {
      return this.$options.filters.nl2br(this.text);
    },
  },
};
</script>
